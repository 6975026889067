import React, { Component } from "react";
import { Link } from "react-router-dom";

import SectionTitle from "../../components/SectionTitles/SectionTitle";

class PriceTable extends Component {
  render() {
    return (
      <div className="section section-padding-t90 section-padding-bottom ">
        <div className="container ">
          <SectionTitle headingOption="fz-32" title="Contact us for better pricing" subTitle="" />
          <div className="row">
            <div className="col-lg-12">
              <div className="section-head text-center">
                <h3></h3>
              </div>
            </div>
          </div>
          <div className="pricingtable-row">
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="pricingtable-wrapper">
                  <div className="pricingtable-inner">
                    <div className="pricingtable-title bg-primary">
                      <h4>Static Webiste</h4>
                    </div>
                    <div className="pricingtable-price d-none">
                      {" "}
                      <span className="pricingtable-bx ">₹ 5,900</span> <span className="pricingtable-type">Year</span>{" "}
                    </div>
                    <ul className="pricingtable-features">
                      <li>Full Responsive </li>
                      <li>1 to 5 Static Pages</li>
                      <li>With Bootstrap</li>
                      <li>Support</li>
                      <li>Domain + Management</li>
                      <li>Unlimited Bandwidth</li>
                      <li>First year Hosting / Support Free</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="pricingtable-wrapper">
                  {/* <div className="pricingtable-inner pricingtable-highlight"> */}
                  <div className="pricingtable-inner ">
                    <div className="pricingtable-title bg-primary">
                      <h4>E-Commerce Website</h4>
                    </div>
                    <div className="pricingtable-price d-none">
                      {" "}
                      <span className="pricingtable-bx">₹ 15,900</span> <span className="pricingtable-type">Year</span>{" "}
                    </div>
                    <ul className="pricingtable-features">
                      <li>Full Responsive </li>
                      <li>Preferred color theme</li>
                      <li>With Bootstrap</li>
                      <li>Domain + Management</li>
                      <li>User authentication</li>
                      <li>Search Products</li>
                      <li>Order / Check out</li>
                      <li>Customer Review / Ratings</li>
                      <li>Static Page 1 to 5</li>
                      <li>Logo design</li>
                      <li>Admin Management</li>
                      <li>Dashboard</li>
                      <li>Unlimited Bandwidth / Limitted Storage</li>
                      <li>First year Hosting / Support Free</li>
                    </ul>
                    {/* <div className="pricingtable-footer">
                      {" "}
                      <Link to={""} className="btn btnhover">
                        Sign Up
                      </Link>{" "}
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="pricingtable-wrapper">
                  {/* <div className="pricingtable-inner pricingtable-highlight"> */}
                  <div className="pricingtable-inner ">
                    <div className="pricingtable-title bg-primary">
                      <h4>Blogging Website</h4>
                    </div>
                    <div className="pricingtable-price d-none">
                      {" "}
                      <span className="pricingtable-bx">₹ 13,900</span> <span className="pricingtable-type">Year</span>{" "}
                    </div>
                    <ul className="pricingtable-features">
                      <li>Blog post</li>
                      <li>Gallery</li>
                      <li>Slider</li>
                      <li>Recent posts</li>
                      <li>Admin Panel</li>
                      <li>Manage all posts</li>
                    </ul>
                    {/* <div className="pricingtable-footer">
                      {" "}
                      <Link to={""} className="btn btnhover">
                        Sign Up
                      </Link>{" "}
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="pricingtable-wrapper">
                  {/* <div className="pricingtable-inner pricingtable-highlight"> */}
                  <div className="pricingtable-inner ">
                    <div className="pricingtable-title bg-primary">
                      <h4>E-Commerce + Blogging Website</h4>
                    </div>
                    <div className="pricingtable-price d-none">
                      {" "}
                      <span className="pricingtable">
                        <del>₹ 17,900</del>
                      </span>
                      <span className="pricingtable-type ">Year</span>
                      <div>
                        <span className="pricingtable-bx">₹ 15,900</span>
                        <span className="pricingtable-type">Year</span>{" "}
                      </div>
                    </div>
                    <ul className="pricingtable-features">
                      <li>E - Commerce Feature </li>
                      <li>Blogging Feature</li>
                    </ul>
                    {/* <div className="pricingtable-footer">
                      {" "}
                      <Link to={""} className="btn btnhover">
                        Sign Up
                      </Link>{" "}
                    </div> */}
                  </div>
                </div>
                <div className="pricingtable-wrapper">
                  {/* <div className="pricingtable-inner pricingtable-highlight"> */}
                  <div className="pricingtable-inner ">
                    <div className="pricingtable-title bg-primary">
                      <h4>SEO</h4>
                    </div>
                    <div className="pricingtable-price d-none">
                      {" "}
                      <span className="pricingtable">
                        <del>₹ 17,900</del>
                      </span>
                      <span className="pricingtable-type ">Year</span>
                      <div>
                        <span className="pricingtable-bx">₹ 15,900</span>
                        <span className="pricingtable-type">Year</span>{" "}
                      </div>
                    </div>
                    <ul className="pricingtable-features">
                      <li>Google Analytics</li>
                      <li>Keywords set up</li>
                      <li>Meta tags set up</li>
                      <li>Site map creation</li>
                      <li>Robotics file creation</li>
                      <li>Website Optimisation</li>
                      <li>Google Indexing and Crawling</li>
                      <li>Google Business Account setup</li>
                      <li>Find on google</li>
                    </ul>
                    {/* <div className="pricingtable-footer">
                      {" "}
                      <Link to={""} className="btn btnhover">
                        Sign Up
                      </Link>{" "}
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 ">
                <div className="pricingtable-wrapper">
                  <div className="pricingtable-inner">
                    <div className="pricingtable-title bg-primary">
                      <h4>Add Ons / New Product</h4>
                    </div>
                    <div className="pricingtable-price">
                      {" "}
                      <span className="pricingtable-bx">Contact Us</span>
                    </div>
                    <ul className="pricingtable-features">
                      <li>Payement Gateway</li>
                      <li>SEO, Metadata tags, Google Analytics</li>
                      <li>Bloging</li>
                      <li>New Theme</li>
                      <li>Custom Email</li>
                      <li>Whats app Chat</li>

                      <li>Photo Gallery</li>
                      <li>Contact us for new requirement</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="pricingtable-wrapper">
                  <div className="pricingtable-inner">
                    <div className="pricingtable-title bg-primary">
                      <h4>Digital Marketting</h4>
                    </div>
                    <div className="pricingtable-price d-none">
                      {" "}
                      <span className="pricingtable-bx">₹ 8,000</span> <span className="pricingtable-type">Month</span>{" "}
                    </div>
                    <ul className="pricingtable-features">
                      <li>3 Post / Week</li>
                      <li>4 Story Promts per week</li>
                      <li>2 Reels Ideas+ Script + Cover Design</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="pricingtable-wrapper">
                  <div className="pricingtable-inner">
                    <div className="pricingtable-title bg-primary">
                      <h4>Digital Marketting</h4>
                    </div>
                    <div className="pricingtable-price d-none">
                      {" "}
                      <span className="pricingtable-bx">₹ 15,000</span> <span className="pricingtable-type">Month</span>{" "}
                    </div>
                    <ul className="pricingtable-features">
                      <li>7 Post / Week</li>
                      <li>6 Story Promts per week</li>
                      <li>7 Reels Ideas+ Script + Cover Design</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="pricingtable-wrapper">
                  <div className="pricingtable-inner">
                    <div className="pricingtable-title bg-primary">
                      <h4>Email Marketting</h4>
                    </div>
                    <div className="pricingtable-price d-none">
                      {" "}
                      <span className="pricingtable-bx">₹ 5,000</span> <span className="pricingtable-type">Month</span>{" "}
                    </div>
                    <ul className="pricingtable-features">
                      <li>Email marketting includes setting up a funnel</li>
                      <li>Launching freebie</li>
                      <li>Newsletter every week</li>
                      <li>Nurture audienace on email</li>
                      <li>Launch main course on email</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PriceTable;
