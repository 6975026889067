import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import config from "../../config.json";
import Parallax from "parallax-js";
import classnames from "classnames";
import { TabContent, TabPane } from "reactstrap";
import { useHistory } from "react-router";

const Login = () => {
  const [message, setMessage] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [fphonenumber, setFphonenumber] = useState("");
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const onSubmit = (data, e) => {
    e.preventDefault();
    console.log("login user", data);
    fetch(config.service_url + "adminlogin", { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify({ phonenumber: data.phonenumber, password: data.password }) })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          console.log(data);
          localStorage.setItem("uuid", data.data.uuid);
          localStorage.setItem("name", data.data.name);
          localStorage.setItem("accessToken", data.data.token);
          localStorage.setItem("pages", JSON.stringify(data.data.pages));
          history.push("/adminhome");
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          //alert(data.message);
          setMessage(data.message);
        }
      })
      .catch((err) => {
        setMessage("Something went wrong, please try again later!!");
      });
  };
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    setMessage("");
  };

  const forgetpassword = async () => {
    if (fphonenumber === "") {
      return setMessage("Please enter registered admin phone number");
    }
    let datas = {};
    datas.phonenumber = fphonenumber;
    console.log("fphonenumber", fphonenumber);
    console.log("req", JSON.stringify({ phonenumber: fphonenumber }));
    await fetch(config.service_url + "forgetpassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({ phonenumber: fphonenumber }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          localStorage.clear();
          setMessage("Please check your registerd email.");
          setFphonenumber("");
          console.log("sucess");
        } else if (data.status === 499) {
          history.push("/login");
        } else {
          setMessage(data.message);
          console.log("error", data.message);
        }
      });
  };
  return (
    <div className="section section-padding-top section-padding-bottom-180">
      <div className="container">
        <div>
          <div className="section-full">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center"></div>
              </div>
              <div className="row align-content-stretch">
                <div className="col-lg-6 col-md-12 m-b30">
                  <div className="p-a30 border-1 radius-sm">
                    <div className="tab-content tab-form nav w-100">
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <form id="login" onSubmit={handleSubmit(onSubmit)} className="tab-pane active col-12 p-a0">
                            <h3 className="m-b5">Admin Login</h3>
                            <p>If you have an account with us, please log in.</p>
                            <div className="form-group">
                              <label>Phone Number *</label>
                              <input name="phonenumber" required type="number" minimum="0" minimum="9999999999" className=" border form-control w-100" defaultValue="hello@example.com" ref={register} />
                              {/* {errors.phonenumber && "Phone Number is required"} */}
                            </div>
                            <div className="form-group">
                              <label>Password *</label>
                              <input name="password" required type="password" className=" border form-control" ref={register} />
                              {/* {errors.password && "Password is required"} */}
                            </div>
                            <div className="text-left">
                              <div className="text-red">{message}</div>
                              <button className="btn btn-primary btnhover" type="submit">
                                Login
                              </button>
                              <Link
                                to={"#"}
                                data-toggle="tab"
                                id="#forgot-password"
                                className={classnames({ active: activeTab === "1" }) + "    m-l10"}
                                onClick={() => {
                                  toggle("2");
                                }}
                              >
                                Forgot Password
                              </Link>
                              <Link
                                to={"#"}
                                data-toggle="tab"
                                id="#change-password"
                                className={classnames({ active: activeTab === "1" }) + "    m-l10"}
                                onClick={() => {
                                  toggle("3");
                                }}
                              >
                                Change Password
                              </Link>
                            </div>
                          </form>
                        </TabPane>
                        <TabPane tabId="2">
                          <form id="forgot-password" className={activeTab === "2" ? "tab-pane fade col-12 p-a0  show" : " tab-pane fade col-12 p-a0 "}>
                            <h4>Forget Password ?</h4>
                            <div className="">
                              <p>We will send you an email to reset your password. </p>
                              <div className="form-group">
                                <label>Phone Number*</label>
                                <input name="forgetphonenumber" value={fphonenumber} required type="number" minimum="0" minimum="9999999999" className=" border form-control w-100" onChange={(e) => setFphonenumber(e.target.value)} />
                              </div>
                              <div>{message}</div>
                              <div className="text-left gray-btn">
                                <Link
                                  className={classnames({ active: activeTab === "2" }) + " btn border btnhover   gray"}
                                  onClick={() => {
                                    toggle("1");
                                  }}
                                  data-toggle="tab"
                                  to={"#"}
                                >
                                  Login
                                </Link>
                                <button type="button" onClick={(e) => forgetpassword()} className="btn btn-primary btnhover pull-right">
                                  Submit
                                </button>
                              </div>
                            </div>
                            <p>Contact us if you have any issue or mail us at info@theuniquecreations.com</p>

                            <div className="d-none">
                              <Link
                                className={classnames({ active: activeTab === "1" }) + " btn border btnhover   gray"}
                                onClick={() => {
                                  toggle("1");
                                }}
                                data-toggle="tab"
                                to={"#"}
                              >
                                Back
                              </Link>
                              <Link className=" btn  btn-primary btnhover  pull-right" to="/contact">
                                Contact us
                              </Link>
                            </div>
                          </form>
                        </TabPane>
                        <TabPane tabId="3">
                          <form id="change-password" className={activeTab === "3" ? "tab-pane fade col-12 p-a0  show" : " tab-pane fade col-12 p-a0 "}>
                            <h4>Change Password ?</h4>
                            <div className="p-2">Please log in to change password.</div>
                            <div className="d-none">
                              <div className="form-group ">
                                <label>
                                  Current Password <span className="required">*</span>
                                </label>
                                <input type="password" aria-required="true" size="30" name="password" className="border" required={false} id="password" onChange={(e) => setOldPassword(e.target.value)} />
                              </div>
                              <div className="form-group">
                                <label>
                                  New Password <span className="required">*</span>
                                </label>
                                <input type="password" aria-required="true" className="border" size="30" name="newpwd" required={false} id="newpwd" onChange={(e) => setNewPassword(e.target.value)} />
                              </div>
                              <div className="form-group">
                                <label>
                                  Confirm Password <span className="required">*</span>
                                </label>
                                <input type="password" aria-required="true" size="30" className="border" name="cnfpwd" required={false} id="cnfpwd" onChange={(e) => setConfirmPwd(e.target.value)} />
                              </div>
                            </div>
                            {message}
                            <div className="text-left gray-btn">
                              <Link
                                className={classnames({ active: activeTab === "1" }) + " btn border btnhover   gray"}
                                onClick={() => {
                                  toggle("1");
                                }}
                                data-toggle="tab"
                                to={"#"}
                              >
                                Back
                              </Link>
                            </div>
                          </form>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 m-b30">
                  <div className="border-1 radius-sm">
                    <img src={config.tucloginimage} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
