import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import config from "../../config.json";
import uuid from "react-uuid";
//import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import firebase from "./../../firebase";
import { MDBDataTableV5 } from "mdbreact";
import Moment from "moment";
import Chart from "./Chart";
import Invoice from "./Invoice";
import AdminManagePosts from "./AdminManagePosts";
import AdminManageGallery from "./AdminManageGallery";
import AdminViewUsers from "./AdminViewUsers";
import on from "./../../assets/images/icons/on.png";
import off from "./../../assets/images/icons/off.png";
import edit from "./../../assets/images/icons/edit.png";
import JoditEditor from "jodit-react";
import deleteicon from "./../../assets/images/icons/delete.png";
import "./../../css/style.css";
import "./../../css/templete.css";
import "./../../css/skin/skin-1.css";
import jsPDF from "jspdf";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";

import { forwardRef } from "react";
import Select from "react-select";
import MaterialTable from "material-table";
import { alpha } from "@material-ui/core/styles";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const AdminOrder = (props) => {
  //const [productDtl, setProductDtl] = useState({});
  //const [validationMsg, setValidationMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [channelId, setChannelid] = useState(0);
  const [product_image, setProduct_image] = useState("");
  const [smShow, setSmShow] = useState(false);

  const [show, setShow] = useState(false);
  const [products, setProducts] = useState([]);
  const history = useHistory();
  const editor = useRef(null);
  const [orderDetails, setorderDetails] = useState([]);
  const [masterorderDetails, setmasterorderDetails] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [editProduct, setEditProduct] = useState({});
  const [networkError, setNetworkError] = useState("");
  const [masterCategory, setMasterCategory] = useState([]);
  const [masterSubCategory, setMasterSubCategory] = useState([]);
  const [p_categorydrp, setp_categorydrp] = useState("");
  const [p_subcategorydrp, setp_subcategorydrp] = useState("");
  //const [dataUri, setDataUri] = useState("");
  const [showCamera, setShowCamera] = useState(false);
  const [ownerNotes, setownerNotes] = useState("");
  const [content, setContent] = useState("");
  const [flag, setFlag] = useState(false);
  const [access, setAccess] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [productsDetails, setProductsDetails] = useState([]);
  const [smShowInvoice, setSmShowInvoice] = useState(false);
  const [smShowMore, setsmShowMore] = useState(false);

  const config_ = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };
  const handleVisible = () => {
    setSmShow(true);
    setTimeout(() => {
      setSmShow(false);
    }, 1000);
  };

  const getAdminOrderHistory = async (e) => {
    await fetch(props.serviceurl + "getAdminOrderHistory", { method: "POST", headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") }, body: JSON.stringify({ userid: localStorage.getItem("uuid") }) })
      .then((response) => response.json())
      .then((data) => {
        console.log("regitered user", data);
        if (data.status === 200) {
          // setSuccessMsg(data.message);
          setorderDetails(data.data);
          setmasterorderDetails(data.data);
          // handleVisible();
        } else if (data.status === 500) {
          setSuccessMsg(data.message);
          handleVisible();
        } else if (data.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
          handleVisible();
          history.push("/adminhome");
        }
      })
      .catch((err) => {
        setSuccessMsg("Something went wrong, Please try again later!!");
        // alert(err);
        handleVisible();
      });
  };

  const getOrderedUserDetails = async (userid) => {
    setEditProduct({});
    if (userid !== userDetails[0]?.userid) {
      setUserDetails([]);
      await fetch(props.serviceurl + "getuserprofileforAdmin", {
        method: "POST",
        headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
        body: JSON.stringify({ userid: userid }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("regitered user", data);
          if (data.status === 200) {
            // setSuccessMsg(data.message);
            setUserDetails(data.data);
            console.log(data.data);
            console.log(userDetails);

            // handleVisible();
          } else {
            setUserDetails([]);
            // handleVisible();
          }
        })
        .catch((err) => {
          setSuccessMsg("Something went wrong, Please try again later!!");
          // alert(err);
          handleVisible();
        });
    }
  };

  const CancelOrder = async (orderid, productid, value, type) => {
    console.log(value);
    const specificOrder = orderDetails
      ?.filter((order) => order.orderid === orderid)
      .map((selOrder) => {
        selOrder?.products.map((prod) => {
          if (prod.p_id === productid) {
            if (type === "p_return") prod.p_order_status = "Returned";
            else prod.p_order_status = "Cancelled";
          }
          return prod;
        });
        return selOrder;
      });
    delete specificOrder[0].orderdate;
    specificOrder[0].grosstotal = value;
    specificOrder[0].updateddate = new Date();
    console.log("cancel order", specificOrder[0]);
    await fetch(props.serviceurl + "updateorder", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ id: orderid, data: specificOrder[0] }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setSuccessMsg(data.message);
          handleVisible();
          getAdminOrderHistory();
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
          handleVisible();
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const CancelWholeOrder = async (orderid, value) => {
    const specificOrder = orderDetails?.filter((a) => a.orderid == orderid);
    console.log("specificorder", specificOrder);
    console.log(value);
    delete specificOrder[0].orderdate;
    specificOrder[0].updateddate = new Date();
    //orderstatus
    if (value === "w_return") specificOrder[0].orderstatus = "Returned";
    else if (value === "w_completed") specificOrder[0].orderstatus = "Completed";
    else if (value === "w_cancel") specificOrder[0].orderstatus = "Cancelled";
    //deliverystatus
    else if (value === "w_delivered") specificOrder[0].deliverystatus = "Delivered";
    else if (value === "w_pickup") specificOrder[0].deliverystatus = "Ready for Pick Up";
    else if (value === "w_shipped") specificOrder[0].deliverystatus = "Shipped";
    else if (value === "w_inprogress") specificOrder[0].deliverystatus = "InProgress";
    //paymentstatus
    else if (value === "pay_received") specificOrder[0].paymentstatus = "Received";
    else if (value === "pay_partial") specificOrder[0].paymentstatus = "Partially Received";
    else if (value === "pay_notreceived") specificOrder[0].paymentstatus = "Not Received";
    //paymentmethod
    else if (value === "paymode_cash") specificOrder[0].paymentmethod = "Cash";
    else if (value === "paymode_online") specificOrder[0].paymentmethod = "Online";
    //owener notes
    else if (value === "ownernotes") specificOrder[0].ownernotes = ownerNotes;
    console.log("cancel whole order", specificOrder[0]);
    delete specificOrder[0].grosstotal;
    //delete specificOrder[0].products; // check and addd this code
    await fetch(props.serviceurl + "updateorder", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ id: orderid, data: specificOrder[0] }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setSuccessMsg(data.message);
          handleVisible();
          getAdminOrderHistory();
        } else if (data?.status === 499) {
          history.push("/shop-login");
        } else {
          setSuccessMsg(data.message);
          handleVisible();
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        //console.log(networkError);
      });
  };

  const checkaccess = async () => {
    let datas = {};
    datas.userid = localStorage.getItem("uuid");
    datas.page = "Order";
    await fetch(props.serviceurl + "checkaccess", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ data: datas }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("access", data);
        if (data.status === 200) {
          setAccess(true);
        } else {
          history.push("/adminhome");
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
      });
  };

  useEffect(() => {
    checkaccess();
    if (access) {
      getAdminOrderHistory();
    }
  }, [access]);

  const columns = [
    {
      title: "Sno",
      field: "sno",
      sort: "enabled",
      width: 150,
    },
    {
      title: "Customer",
      field: "customer",
      sort: "disabled",
      width: 150,
    },
    {
      title: "Date",
      field: "date",
      sort: "enabled",
      width: 150,
    },
    {
      title: "Order",
      field: "order",
      sort: "disabled",
      width: 150,
    },
    {
      title: "Payment",
      field: "payment",
      sort: "disabled",
      width: 150,
    },
    {
      title: "Total",
      field: "total",
      sort: "disabled",
      width: 150,
    },
    {
      title: "Delivery",
      field: "delivery",
      sort: "disabled",
      width: 150,
    },
    {
      title: "Notes",
      field: "notes",
      sort: "disabled",
      width: 150,
    },
    {
      title: "Invoice",
      field: "action",
      sort: "disabled",
      width: 150,
    },
  ];
  const orderdata =
    orderDetails &&
    orderDetails.map((dtl, index) => ({
      searchorder: dtl.orderstatus,
      searchname: dtl.name,
      searchpayemnt: dtl.paymentstatus,
      searchdelivery: dtl.deliverystatus,
      searchname: dtl.email,
      sno: index + 1,
      customer: (
        <>
          {dtl.shippingaddress && dtl.shippingaddress?.name}
          <div className="px-2 border d-none rounded bg-white z-index">
            <b>Shipping Address :</b>
            {dtl.shippingaddress?.address}
            <br />
            <b>City:</b>
            {dtl.shippingaddress?.city},<br />
            <b>State:</b>
            {dtl.shippingaddress?.state},{dtl.shippingaddress?.pincode}
            <br />
            <b>Phone :</b>
            {dtl.shippingaddress?.phonenumber}
          </div>
        </>
      ),
      date: Moment(dtl.orderdate).format("DD-MMM-YYYY"),
      order: (
        <div className="d-flex justify-content-between">
          <div>
            <b className={dtl.orderstatus === "Completed" ? "text-success" : "text-dark"}> {dtl.orderstatus} </b>
          </div>
          <div className="px-1">
            <div className="dropdown dropleft">
              <button className="btndrp btn-secondary dropdown-toggle p-1" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
              <div className="dropdown-menu px-3" aria-labelledby="dropdownMenu2">
                <button className="p-1 m-1 btnadmin bg-success dropdown-item " onClick={(e) => CancelWholeOrder(dtl.orderid, "w_completed")}>
                  Complete
                </button>
                <button className="p-1 m-1 btnadmin  text-dark bg-warning dropdown-item " onClick={(e) => CancelWholeOrder(dtl.orderid, "w_cancel")}>
                  Cancel
                </button>{" "}
                <button className="p-1 m-1 btnadmin bg-secondary dropdown-item" onClick={(e) => CancelWholeOrder(dtl.orderid, "w_return")}>
                  Return
                </button>{" "}
              </div>
            </div>
          </div>
        </div>
      ),
      payment: (
        <div className="d-flex justify-content-between">
          <div>{dtl.paymentstatus}</div>
          <div>
            <div>
              <div className="px-1">
                <div className="dropdown dropleft">
                  <button className="btndrp btn-secondary dropdown-toggle p-1" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                  <div className="dropdown-menu px-3" aria-labelledby="dropdownMenu2">
                    <button className="p-1 m-1 btnadmin  bg-success dropdown-item" onClick={(e) => CancelWholeOrder(dtl.orderid, "pay_received")}>
                      Received
                    </button>
                    <button className="p-1 m-1 text-dark btnadmin bg-warning dropdown-item" onClick={(e) => CancelWholeOrder(dtl.orderid, "pay_partial")}>
                      Partially
                    </button>
                    <button className="p-1 m-1 btnadmin bg-primary dropdown-item" onClick={(e) => CancelWholeOrder(dtl.orderid, "pay_notreceived")}>
                      NotReceived
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
      total: <span> ₹ {dtl.grosstotal}</span>,
      delivery: (
        <div className="d-flex justify-content-between">
          <div>{dtl.deliverystatus} </div>
          <div>
            <div className="px-1">
              <div className="dropdown dropleft">
                <button className="btndrp btn-secondary dropdown-toggle p-1" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                <div className="dropdown-menu px-3" aria-labelledby="dropdownMenu2">
                  <button className="p-1 m-1 btnadmin bg-success dropdown-item " onClick={(e) => CancelWholeOrder(dtl.orderid, "w_delivered")}>
                    Delivered
                  </button>
                  <button className="p-1 m-1 btnadmin text-dark bg-warning dropdown-item " onClick={(e) => CancelWholeOrder(dtl.orderid, "w_inprogress")}>
                    In Progress
                  </button>
                  <button className="p-1 m-1 btnadmin bg-primary dropdown-item " onClick={(e) => CancelWholeOrder(dtl.orderid, "w_pickup")}>
                    Pick up
                  </button>
                  <button className="p-1 m-1 btnadmin text-dark bg-info dropdown-item " onClick={(e) => CancelWholeOrder(dtl.orderid, "w_shipped")}>
                    Shipped
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
      notes: (
        <div className="d-flex justify-content-between">
          <div>
            <b>Message to Customer:</b>
          </div>
          <div>
            <div>
              <div className="px-1">
                <div className="dropdown dropleft">
                  <button className="btndrp btn-secondary dropdown-toggle p-1" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                  <div className="dropdown-menu px-3" aria-labelledby="dropdownMenu2">
                    <textarea type="textarea" rows="3" defaultValue={dtl.ownernotes} className="form-control w-100" placeholder="Owner Notes" onChange={(e) => setownerNotes(e.target.value)}></textarea>
                    <button className="p-1 m-1 btnadmin text-dark bg-warning  dropdown-item" onClick={(e) => CancelWholeOrder(dtl.orderid, "ownernotes")}>
                      Update
                    </button>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
      action: (
        <>
          <a className="px-1 text-dark" onClick={(e) => ShowMoreDetails(dtl, dtl.products, dtl.userid)}>
            More
          </a>{" "}
          <a className="text-dark d-none" onClick={(e) => CreateInvoice(dtl)}>
            Invoice
          </a>
          <br />
          <div className="d-none">
            <div className="row accordion py-1  rounded" id="accordionproduct">
              <div className="accordion-item1">
                <p className="accordion-header" id={"headingOne" + index}>
                  <button className=" p-1 btnadmin btnhover rounded border collapsed" type="button" onClick={(e) => getOrderedUserDetails(dtl.userid)} data-bs-toggle="collapse" data-bs-target={"#collapseOne" + index} aria-expanded="false" aria-controls={"#collapseOne" + index}>
                    View all Products
                  </button>
                </p>
                <div id={"collapseOne" + index} className="accordion-collapse collapse" aria-labelledby={"headingOne" + index} data-bs-parent="#accordionproduct">
                  <div className="accordion-body">
                    {userDetails?.map((user) => {
                      return (
                        <>
                          <b> {user.name} </b>
                          <span>
                            {user.address + ", " + user.city + ", " + user.state + ", " + user.pincode + "(Phone: " + user.phonenumber + ")"}, {} <br></br>
                          </span>

                          <span> {user.email} </span>
                        </>
                      );
                    })}
                    <br />
                    <br />
                    {dtl.products?.map((productDtl) => {
                      return (
                        <div>
                          <div className="content-block">
                            <div className="section-full py-3">
                              <div>
                                <div className="row">
                                  <div className="col-lg-3 ">
                                    <img className="mediumimage" src={productDtl.p_image} alt="" />
                                  </div>
                                  <div className="col-lg-9">
                                    <div className="row">
                                      <div className="col">
                                        <div className="mb-2">
                                          {/* <Link to={{ pathname: `/shop-product-details/${productDtl.p_id}` }}>{productDtl.p_name}</Link> */}
                                          <Link to="#">{productDtl.p_name}</Link>
                                        </div>
                                        <div className="mb-2 text-nowrap">
                                          Price per Unit: <i className="fa fa-inr"></i> {productDtl.p_price} | Qty: {productDtl.p_quantity}
                                        </div>{" "}
                                        <div>
                                          <b>
                                            Total:<i className="fa fa-inr"></i>
                                            {productDtl.p_quantity * productDtl.p_price}
                                          </b>
                                        </div>
                                      </div>
                                      <div className="col">
                                        <div className="mt-2">
                                          Status:
                                          <b className="text-dark"> {productDtl.p_order_status}</b>
                                        </div>
                                        <div>
                                          <button className="p-1 m-1 btnadmin text-dark bg-warning" disabled={productDtl.p_order_status === "Cancelled" || productDtl.p_order_status === "Returned" ? true : false} onClick={(e) => CancelOrder(dtl.orderid, productDtl.p_id, dtl.grosstotal - productDtl.p_quantity * productDtl.p_price, "p_cancel")}>
                                            Cancel
                                          </button>{" "}
                                          <button className="p-1 m-1 btnadmin bg-secondary" disabled={productDtl.p_order_status === "Cancelled" || productDtl.p_order_status === "Returned" ? true : false} onClick={(e) => CancelOrder(dtl.orderid, productDtl.p_id, dtl.grosstotal, "p_return")}>
                                            Return
                                          </button>{" "}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ),
    }));

  const CreateInvoice = (order) => {
    ShowInvoice();
    setInvoiceDetails(order);
  };
  const ShowInvoice = () => {
    setSmShowInvoice(true);
  };
  const ShowMoreDetails = (orderdetails, products, userid) => {
    setInvoiceDetails(orderdetails);
    getOrderedUserDetails(userid);
    setProductsDetails(products);
    setsmShowMore(true);
  };
  const filterOrder = (orderstatus) => {
    if (orderstatus == "") {
      setorderDetails(masterorderDetails);
    } else {
      setorderDetails(masterorderDetails.filter((order) => order.orderstatus === orderstatus));
    }
  };
  const _printEverything = () => {
    const doc = new jsPDF();

    //get table html
    const pdfTable = document.getElementById("divToPrint");

    //html to pdf format
    var html = htmlToPdfmake(pdfTable.innerHTML);

    const documentDefinition = { content: html };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(documentDefinition).open();
  };
  const shippingpartner = (ShippingDetails) => {
    console.log(ShippingDetails);
    let logindata = {};
    // call api and get this details from db /Shopping/daintyflavours.com/accountconfig/shippingaccountconfig
    logindata.email = "vbalakumar.cse1@gmail.com";
    logindata.password = "bala@123";
    logindata.pickuploaction = "test";
    logindata.channelid = "2792430";
    if (localStorage.getItem("shiprockettoken")) {
      const channelid_ = getChannel(localStorage.getItem("shiprockettoken"));
      submitOrdertoShiprocket(channelid_ == 0 ? logindata.channelid : channelid_, localStorage.getItem("shiprockettoken"), ShippingDetails, logindata.pickuploaction);
    } else {
      fetch(config.shiprocketloginurl, {
        method: "POST",
        headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
        body: JSON.stringify({ email: logindata.email, password: logindata.password }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.token != "" || logindata.email === data.email) {
            console.log(data);
            //call channel
            const channelid_ = getChannel(data.token);
            localStorage.setItem("shiprockettoken", data.token);
            submitOrdertoShiprocket(channelid_, data.token, ShippingDetails);
          } else if (data?.status_code === 400) {
            console.log(data.message);
            setSuccessMsg(data.message);
            handleVisible();
          } else {
            console.log(data.email);
            setSuccessMsg(data.message);
            handleVisible();
          }
        })
        .catch((err) => {
          setNetworkError("Something went wrong, Please try again later!!");
          //console.log(networkError);
        });
    }
    // post service for shipping partnet
    // call authentication
    // call channel
  };
  function getChannel(token) {
    let id = 0;
    let channelid = fetch(config.shiprocketchannelurl, {
      method: "GET",
      headers: { "Content-Type": "application/json", authorization: "Bearer " + token },
      //body: JSON.stringify({ email: logindata.email, password: logindata.password }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.data[0].status_code === 1 || data.data[0].status === "Active") {
          //call channel
          console.log("channelid2", data.data[0].id);
          id = data.data[0].id;
          setChannelid(data.data[0].id);
        } else if (data?.status_code === 401) {
          setSuccessMsg("Invalid user, please update ship rocket log in");
          handleVisible();
          setChannelid(0);
        } else {
          setSuccessMsg("Error Occured, please try again later, or contact admin");
          handleVisible();
          setChannelid(0);
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        //console.log(networkError);
        setChannelid(0);
      });
    return channelId;
  }
  function submitOrdertoShiprocket(channelid_, token, orderdetails, pickuploaction) {
    try {
      if (channelid_ == 0) {
        setSuccessMsg("Invalid Channel id");
        handleVisible();
        return;
      }
      let newProducts = [];
      console.log("rderdetails.products products", orderdetails.products);
      console.log("submitOrdertoShiprocket products", newProducts);
      Object.entries(orderdetails?.products).forEach((data1, index) => {
        let key = data1[1];
        console.log("submitOrdertoShiprocket key index", key);
        let _newObj = {};
        _newObj.name = data1[1].p_name;
        _newObj.sku = data1[1].p_id;
        _newObj.units = data1[1].p_quantity;
        _newObj.selling_price = data1[1].p_net_product_price;
        _newObj.discount = "";
        _newObj.tax = data1[1].p_tax;
        _newObj.hsn = 0;
        newProducts.push(_newObj);
      });

      console.log("submitOrdertoShiprocket channel", channelid_);
      console.log("submitOrdertoShiprocket products", newProducts);

      var orderdata = {
        order_id: orderdetails.orderid,
        order_date: orderdetails.orderdate,
        pickup_location: pickuploaction,
        channel_id: channelid_,
        comment: orderdetails.usernotes,
        billing_customer_name: orderdetails.billingaddress.name,
        billing_last_name: "",
        billing_address: orderdetails.billingaddress.address,
        billing_address_2: "",
        billing_city: orderdetails.billingaddress.city,
        billing_pincode: orderdetails.billingaddress.pincode,
        billing_state: orderdetails.billingaddress.state,
        billing_country: "India",
        billing_email: orderdetails.billingaddress.email,
        billing_phone: orderdetails.billingaddress.phonenumber,
        shipping_is_billing: false,
        shipping_customer_name: orderdetails.shippingaddress.name,
        shipping_last_name: "",
        shipping_address: orderdetails.shippingaddress.address,
        shipping_address_2: "",
        shipping_city: orderdetails.shippingaddress.city,
        shipping_pincode: orderdetails.shippingaddress.pincode,
        shipping_country: "India",
        shipping_state: orderdetails.shippingaddress.state,
        shipping_email: orderdetails.shippingaddress.email,
        shipping_phone: orderdetails.shippingaddress.phonenumber,
        order_items: newProducts,
        payment_method: "Prepaid",
        shipping_charges: orderdetails.shipping,
        giftwrap_charges: 0,
        transaction_charges: 0,
        total_discount: 0,
        sub_total: orderdetails.grosstotal,
        length: 10,
        breadth: 10,
        height: 4,
        weight: 0.2,
      };
      console.log("shiporder order details", orderdetails);
      console.log("shiporder input", JSON.stringify({ orderdata }));
      fetch(config.shiprocketcreateorderurl, {
        method: "POST",
        headers: { "Content-Type": "application/json", authorization: "Bearer " + token },
        body: JSON.stringify(orderdata),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status_code === 1) {
            //call channel

            console.log("sent to ship rocket");
            setSuccessMsg("Sucessfully sent to ShipRocket for Shipping.");
            handleVisible();
          } else if (data?.status_code === 401) {
            console.log("channel", data.message);
            setSuccessMsg(data.message);
            handleVisible();
          } else if (data?.status === "CANCELED") {
            setSuccessMsg("Order Cancelled in Shiprocket");
            handleVisible();
          } else {
            setSuccessMsg("Error Occured, Try agian later, or Contact admin");
            handleVisible();
          }
        })
        .catch((err) => {
          setNetworkError("Something went wrong, Please try again later!!");
        });
    } catch (error) {
      console.log("Error", error);
    }
  }

  {
    return access ? (
      <div>
        <Modal size="sm" className="z-index" show={smShow} onHide={() => setSmShow(false)}>
          <Modal.Header closeButton>
            <span className="text-dark"> {successMsg}</span>
          </Modal.Header>
        </Modal>
        <Modal size="lg" className="z-index" show={smShowInvoice} onHide={() => setSmShowInvoice(false)}>
          <Modal.Header closeButton>
            <span className="text-dark"> Invoice</span>
          </Modal.Header>
          <Modal.Body>
            <span>
              <Invoice orderInvoice={invoiceDetails}></Invoice>
            </span>
          </Modal.Body>
        </Modal>
        <Modal size="lg" className="z-index" show={smShowMore} onHide={() => setsmShowMore(false)}>
          <Modal.Header closeButton>
            <span className="text-dark"> More Details</span>
          </Modal.Header>
          <Modal.Body>
            <button className="btn btn-primary px-1" onClick={(e) => _printEverything()}>
              Generate Invoice
            </button>
            {/* get this daintyflavors.com from log in response */}
            {props.client === "daintyflavors.com" ? (
              <button className="btn btn-primary px-1" onClick={(e) => shippingpartner(invoiceDetails)}>
                Send the package for Pick up
              </button>
            ) : (
              ""
            )}
            <div id="divToPrint">
              <div>
                <table>
                  <tr>
                    <td>
                      {userDetails?.map((user) => {
                        return (
                          <>
                            <b className="text-dark "> User Details:</b> <br />
                            Name: <b> {user.name} </b>
                            <br />
                            Address: {user.address}
                            <br />
                            City/State:{user.city + "/ " + user.state}
                            <br />
                            Pincode: {user.pincode}
                            <br />
                            Phone: {user.phonenumber}
                            <br />
                            Email:{user.email}
                          </>
                        );
                      })}
                    </td>
                    <td>
                      <b className="text-dark "> Shipping Address:</b> <br />
                      Name: <b> {invoiceDetails.shippingaddress?.name} </b>
                      <br />
                      Address: {invoiceDetails.shippingaddress?.address} <br />
                      City/State:{invoiceDetails.shippingaddress?.city}/{invoiceDetails.shippingaddress?.state} <br /> Pincode: {invoiceDetails.shippingaddress?.pincode} <br />
                      Phonenumber: {invoiceDetails.shippingaddress?.phonenumber}
                      <br />
                      Email: {invoiceDetails.shippingaddress?.email}
                    </td>
                  </tr>
                  <tr style={{ borderTop: "none", borderBottom: "none" }}>
                    <td>User Notes: {invoiceDetails.usernotes}</td>
                    <td>Order ID: {invoiceDetails.orderid}</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <b className="text-dark ">Product Details</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      {" "}
                      {productsDetails?.map((productDtl, index) => {
                        return (
                          <div>
                            <div className="content-block">
                              <div className="section-full">
                                <div>
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="row">
                                        <div className="col">
                                          <div className="">
                                            {/* <Link to={{ pathname: `/shop-product-details/${productDtl.p_id}` }}>{productDtl.p_name}</Link> */}
                                            <b className="text-dark ">
                                              {index + 1} {")"} {productDtl.p_name}
                                            </b>
                                          </div>
                                          <div className="text-nowrap">
                                            Price per Unit: <i className="fa fa-inr"></i> {productDtl.p_price} | Qty: {productDtl.p_quantity}{" "}
                                            <b>
                                              Total:<i className="fa fa-inr"></i>
                                              {productDtl.p_quantity * productDtl.p_price}
                                            </b>
                                            {productDtl.p_tax ? "| Tax : " + productDtl.p_tax + "%" : ""}
                                          </div>{" "}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <b className="text-dark ">Order Details</b>
                    </td>
                  </tr>
                  <tr style={{ borderBottom: "none" }}>
                    <td>Order status:</td>
                    <td>{invoiceDetails.orderstatus}</td>
                  </tr>
                  <tr style={{ borderTop: "none", borderBottom: "none" }}>
                    <td>{invoiceDetails.razororderid ? "Razor pay Order id : " : ""}</td>
                    <td>{invoiceDetails.razororderid}</td>
                  </tr>
                  <tr style={{ borderTop: "none", borderBottom: "none" }}>
                    <td>Payement Status:</td>
                    <td>{invoiceDetails.paymentstatus}</td>
                  </tr>
                  <tr style={{ borderTop: "none", borderBottom: "none" }}>
                    <td>Shipping Cost:</td>
                    <td> ₹ {invoiceDetails.shipping} </td>
                  </tr>
                  {invoiceDetails.tax && (
                    <tr style={{ borderTop: "none", borderBottom: "none" }}>
                      <td>Tax:</td>
                      <td>₹ {invoiceDetails.tax}</td>
                    </tr>
                  )}
                  <tr style={{ borderTop: "none" }}>
                    <td> Total(+tax):</td>
                    <td> ₹ {invoiceDetails.grosstotal}</td>
                  </tr>
                  <tr style={{ borderTop: "none" }}>
                    <td> Gross Total(Tax+Shipping)</td>
                    <td> ₹ {invoiceDetails.shipping + invoiceDetails.grosstotal}</td>
                  </tr>
                </table>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <span className="px-2">Total Orders ({orderDetails.length > 0 ? orderDetails.length : 0})</span>
            <select className="form-select w-25 " id="orderstatus" name="orderstatus" onChange={(e) => filterOrder(e.target.value)}>
              <option value={""}>{"Filter"}</option>
              <option value={"Completed"}>{"Completed"}</option>
              <option value={"Cancelled"}>{"Cancelled"}</option>
              <option value={"Returned"}>{"Returned"}</option>
            </select>{" "}
          </div>
          <hr />
          <div className="overflow-auto px-1">
            {/* <MDBDataTableV5 disableRetreatAfterSorting={true} entriesOptions={[5, 20, 25]} entries={30} pagesAmount={4} data={datatable} pagingTop={false} searchTop searchBottom={false} /> */}
            <small>
              <MaterialTable title="All Dainty Flavors Orders" icons={tableIcons} data={orderdata.length > 0 ? orderdata : [{ sno: "Loading..." }]} columns={columns} options={{ search: true, paging: true, filtering: false, sorting: true, pageSize: 20, exportButton: true }} />
            </small>
          </div>
        </div>
      </div>
    ) : (
      <div></div>
    );
  }
};

export default AdminOrder;
