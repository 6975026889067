import React, { useState, useEffect, useRef } from "react";
import config from "../../config.json";
import { Link, useHistory } from "react-router-dom";
import uuid from "react-uuid";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import firebase from "./../../daintyfirebase";
import JoditEditor from "jodit-react";
import Moment from "moment";
import on from "./../../assets/images/icons/on.png";
import off from "./../../assets/images/icons/off.png";
import edit from "./../../assets/images/icons/edit.png";
import deleteicon from "./../../assets/images/icons/delete.png";
import copy from "./../../assets/images/icons/copy.png";
import show from "./../../assets/images/icons/show.png";
import hide from "./../../assets/images/icons/hide.png";
const AdminPostCategory = (props) => {
  const [cat, setCategory] = useState([]);
  const [subcat, setSubCat] = useState([]);
  const [brand, setBrand] = useState([]);
  const [maincat, setMainCategory] = useState([]);
  const [thumbnail_image, setThumbnail_image] = useState("");
  const [banner_image, setBanner_image] = useState("");
  const [networkError, setNetworkError] = useState("");
  const [gallery_image, setGallery_image] = useState("");
  const history = useHistory();
  const [smShow, setSmShow] = useState(false);
  const [showp, setShowp] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [access, setAccess] = useState(false);
  const [catTypeValue, setcatTypeValue] = useState("");
  const [catName, setcatName] = useState("");
  const handleVisible = () => {
    setSmShow(true);
    setTimeout(() => {
      setSmShow(false);
    }, 1000);
  };
  const getMainCategoryDetails = async () => {
    await fetch(config.daintyservice_url + "getmaincategory", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          let _filterData_main_category = data.data.filter((_d) => _d.type === props.type);
          setMainCategory(_filterData_main_category);
        } else if (data.status === 400) {
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const getCategoryDetails = async () => {
    await fetch(config.daintyservice_url + "getcategory", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          let _filterData = data.data.filter((_d) => _d.type === props.type);
          setCategory(_filterData);
        } else if (data.status === 400) {
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const getSubCategories = async () => {
    await fetch(config.daintyservice_url + "getsubcategory", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          let _filterData = data.data.filter((_d) => _d.type === props.type);
          console.log(_filterData);
          console.log(data.data);
          setSubCat(_filterData);
        } else if (data.status === 400) {
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const getBrand = async () => {
    await fetch(config.daintyservice_url + "getbrand", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          let _filterData = data.data.filter((_d) => _d.type === props.type);
          console.log(_filterData);
          console.log(data.data);
          setBrand(_filterData);
        } else if (data.status === 400) {
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const deletemaincat = (id) => {
    let text = "Are you sure to delete ? \nDeleting the item cannot be retrived again.";
    if (window.confirm(text) == true) {
      console.log("catid, confirm delete yes", id);
      fetch(config.daintyservice_url + "deletemaincat", {
        method: "POST",
        headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
        body: JSON.stringify({ Id: id }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(JSON.stringify({ Id: id }));
          getMainCategoryDetails();
        })
        .catch((err) => {
          setNetworkError("Something went wrong, Please try again later!!");
          //console.log(networkError);
        });
    } else {
      console.log("catid, confirm delete Cancel", id);
      return;
    }
  };
  const deletecat = (id) => {
    let text = "Are you sure to delete ? \nDeleting the item cannot be retrived again.";
    if (window.confirm(text) == true) {
      console.log("catid", id);
      fetch(config.daintyservice_url + "deletecat", {
        method: "POST",
        headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
        body: JSON.stringify({ Id: id }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(JSON.stringify({ Id: id }));
          getCategoryDetails();
        })
        .catch((err) => {
          setNetworkError("Something went wrong, Please try again later!!");
          //console.log(networkError);
        });
    } else {
      return;
    }
  };
  const deletesubcat = (id) => {
    let text = "Are you sure to delete ? \nDeleting the item cannot be retrived again.";
    if (window.confirm(text) == true) {
      console.log(id);
      //deleteFromFirebase(url); need to fix it
      fetch(config.daintyservice_url + "deletesubcat", {
        method: "POST",
        headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
        body: JSON.stringify({ Id: id }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(JSON.stringify({ Id: id }));
          getSubCategories();
        })
        .catch((err) => {
          setNetworkError("Something went wrong, Please try again later!!");
          //console.log(networkError);
        });
    } else {
      return;
    }
  };
  const deleteBrand = (id) => {
    let text = "Are you sure to delete ? \nDeleting the item cannot be retrived again.";
    if (window.confirm(text) == true) {
      console.log("catid", id);
      fetch(config.daintyservice_url + "deletebrand", {
        method: "POST",
        headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
        body: JSON.stringify({ Id: id }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(JSON.stringify({ Id: id }));
          getBrand();
        })
        .catch((err) => {
          setNetworkError("Something went wrong, Please try again later!!");
          //console.log(networkError);
        });
    } else {
      return;
    }
  };

  const checkaccess = async () => {
    let datas = {};
    datas.userid = localStorage.getItem("uuid");
    datas.page = "DaintyItems";
    await fetch(config.daintyservice_url + "checkaccess", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ data: datas }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("access", data);
        if (data.status === 200) {
          setAccess(true);
        } else {
          history.push("/adminhome");
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
      });
  };

  const onChange_PostType = (value) => {
    setcatTypeValue(value);
  };
  const onChange_image_thumbnail = async (e) => {
    setSuccessMsg("Please wait");
    setSmShow(true);
    const file = e.target.files[0];
    console.log("file", e.target.files[0]);
    if (file.size < 300000) {
      console.log("add products", file);
      const path = localStorage.getItem("name") + "-" + localStorage.getItem("uuid") + "/banner";
      const storageRef = firebase.app("daintyfb").storage().ref(path);
      const fileRef = storageRef.child(uuid());
      await fileRef.put(file);
      setThumbnail_image(await fileRef.getDownloadURL());
      console.log("add products", thumbnail_image);
      setSmShow(false);
      setSuccessMsg("");
    } else {
      setSuccessMsg("Please upload file less than 300 kb");
    }
  };

  const onChange_image_banner = async (e) => {
    setSuccessMsg("Please wait");
    setSmShow(true);
    const file = e.target.files[0];
    console.log("file", e.target.files[0]);
    if (file.size < 300000) {
      console.log("add products", file);
      const path = localStorage.getItem("name") + "-" + localStorage.getItem("uuid") + "/banner";
      const storageRef = firebase.app("daintyfb").storage().ref(path);
      const fileRef = storageRef.child(uuid());
      await fileRef.put(file);
      setBanner_image(await fileRef.getDownloadURL());
      console.log("add products", banner_image);
      setSmShow(false);
      setSuccessMsg("");
    } else {
      setSuccessMsg("Please upload file less than 300 kb");
    }
  };

  const deleteFromFirebase = (url) => {
    console.log("File url : " + url);
    var fileRef = firebase.storage().refFromURL(url);
    // Delete the file using the delete() method
    fileRef
      .delete()
      .then(function () {
        // File deleted successfully
        console.log("File Deleted");
        setSuccessMsg("Deleted Sucessfully");
        handleVisible();
      })
      .catch(function (error) {
        console.log("File error", error);
      });

    // console.log("File in database after delete exists : " + fileRef?.exists());
  };
  useEffect(() => {
    // checkaccess();
    //if (access)
    {
      getMainCategoryDetails();
      getCategoryDetails();
      getSubCategories();
      getBrand();
      console.log("maincat", props.maincategoryvalue, "cat", props.categoryvalue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access]);
  const updategallery = (type, pid, id) => {
    console.log(id);
    let post = {};
    post.gallery_id = pid;
    post.updateddate = new Date();
    post.viewingallery = id;
    fetch(config.daintyservice_url + "updategallery", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },

      body: JSON.stringify({ data: post }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(JSON.stringify({ data: post }));
        setSuccessMsg(data.message);
        handleVisible();
        getCategoryDetails();
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: {}, mode: "blur" });
  const onSubmit = (data, e) => {
    let methodname;
    setSuccessMsg("Please wait");
    setSmShow(true);
    e.preventDefault();
    if (localStorage.getItem("uuid") === undefined || localStorage.getItem("uuid") === null) {
      history.push("/");
    } else {
      const cattype = catTypeValue;
      data.id = props.type + "_" + uuid();
      data.createddate = new Date();
      data.isactive = 1;
      data.type = props.type;
      if (catTypeValue === "category") {
        methodname = "addcategory";
        data.category = catName;
        data.thumbnail_image = thumbnail_image;
        data.banner_image = banner_image;
        if (props?.maincategoryvalue === undefined || props?.maincategoryvalue === "") {
          console.log("Please select Main Category and enter Category");
          setSuccessMsg("Please select Main Category and enter Category");

          return;
        } else {
          data.maincategory = props?.maincategoryvalue;
        }
      } else if (catTypeValue === "maincategory") {
        methodname = "addmaincategory";
        data.maincategory = catName;
        data.thumbnail_image = thumbnail_image;
        data.banner_image = banner_image;
      } else if (catTypeValue === "brand") {
        methodname = "addbrand";
        data.brand = catName;
        data.thumbnail_image = thumbnail_image;
        data.banner_image = banner_image;
      } else if (catTypeValue === "subcategory") {
        methodname = "addsubcategory";
        data.thumbnail_image = thumbnail_image;
        data.banner_image = banner_image;
        data.subcategory = catName;
        if (props?.maincategoryvalue === undefined || props?.maincategoryvalue === "" || props?.categoryvalue === undefined || props?.categoryvalue === "") {
          console.log("Please select Main Category,Category  and enter Sub Category");
          setSuccessMsg("Please select Main Category,Category  and enter Sub Category");
          return;
        } else {
          data.maincategory = props?.maincategoryvalue;
        }
        data.category = props?.categoryvalue;
        data.maincategory = props?.maincategoryvalue;
      } else {
        setSuccessMsg("Please select Type");
        handleVisible();
        setSmShow(false);
        return;
      }
    }
    console.log(data);
    fetch(config.daintyservice_url + methodname, { method: "POST", headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") }, body: JSON.stringify({ data }) })
      .then((response) => response.json())
      .then((data) => {
        console.log("regitered user", data);
        if (data.status === 200) {
          e.target.reset();
          setSuccessMsg(data.message);
          setSmShow(false);
          handleVisible();
          getCategoryDetails();
          getSubCategories();
        } else {
          setSuccessMsg(data.message);
        }
      })
      .catch((err) => {
        setSuccessMsg("Something went wrong, Please try again later!!");
      });
  };

  {
    return (
      <div>
        <Modal size="sm" show={smShow} onHide={() => setSmShow(false)}>
          <Modal.Header closeButton>{successMsg}</Modal.Header>
        </Modal>
        <div id="review_form_wrapper">
          <div className={!showp ? "" : "d-none"} id="review_form">
            <button className="btnadmin my-2" onClick={(e) => setShowp((showp) => !showp)}>
              View all Main Cat ({maincat.length > 0 ? maincat.length : 0})/ Cat ({cat.length > 0 ? cat.length : 0})/Subcat ({subcat.length > 0 ? subcat.length : 0})/ Brand ({brand.length > 0 ? brand.length : 0})
            </button>
            <div id="respond" className="comment-respond">
              <p className="" id="reply-title">
                Add Category/Subcategory/Brand
              </p>

              <form className="comment-form" onSubmit={handleSubmit(onSubmit)} id="frmPostadd">
                <div className="row">
                  <div className="col comment-form-author">
                    <label>
                      Type <span className="required">*</span>
                    </label>
                    <select className="form-select" id={catTypeValue} name={catTypeValue} required onChange={(e) => onChange_PostType(e.target.value)}>
                      <option value={""}>{""}</option>
                      {props?.maincategory ? <option value="maincategory">Main Category</option> : ""}
                      <option value="category">Category</option>
                      <option value="subcategory">Sub Category</option>
                      <option value="brand">Brand</option>
                    </select>
                    {/* {errors.p_name && "Product name is required"} */}
                  </div>
                  <div className="col comment-form-author">
                    <label>
                      Name <span className="required">*</span>
                    </label>
                    <input type="text" className="form-control" required aria-required="true" size="30" name="category" id="category" onChange={(e) => setcatName(e.target.value)} />
                    {/* {errors.p_name && "Product name is required"} */}
                  </div>
                </div>
                <div className={props.maincategory ? "" : "d-none"}>
                  <div className="">
                    <div className="row">
                      <div className="col ">
                        <label className="text-nowrap">Thumbnail Image </label>
                        <input placeholder="Upload Thumbnail Image" name="thumbnailimage" onChange={onChange_image_thumbnail} accept="image/*" className="form-control" type="file" />
                      </div>
                      <div className="col">
                        <label>Image (max.Size-300Kb)</label>
                        <img className="smallimage" src={thumbnail_image} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col ">
                        <label className="text-nowrap">Banner Image </label>
                        <input placeholder="Upload Banner Image" name="bannerimage" onChange={onChange_image_banner} accept="image/*" className="form-control" type="file" />
                      </div>
                      <div className="col">
                        <label>Image (max.Size-300Kb)</label>
                        <img className="smallimage" src={banner_image} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-submit">
                  <div>{successMsg}</div>
                  <button type="submit" className="btnadmin btnhover">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div id="product" className={showp ? "table check-tbl" : "d-none"}>
            <button
              className="btnadmin my-2"
              onClick={(e) => {
                setShowp((showp) => !showp);
                setGallery_image("");
              }}
            >
              Add
            </button>
            <br />
            Main Categories
            <table className={props.maincategory ? "table check-tbl" : "d-none"}>
              <thead>
                <tr>
                  <th>Main Category</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {maincat.length > 0
                  ? maincat.map((post, key) => (
                      <tr>
                        <td className="product-item-name font-weight-normal">{post.maincategory}</td>
                        <td className="product-item-name font-weight-normal">{post.type}</td>
                        <td className="product-item-name font-weight-normal">{post.isactive === 1 ? "Active" : <span className="text-red">Inactive</span>}</td>
                        <td className="product-item-price font-weight-normal text-no-wrap d-none">{Moment(post.createddate).format("DD-MMM-YYYY")}</td>

                        <td>
                          <Link to="#" onClick={(e) => deletemaincat(post.id)}>
                            <img src={deleteicon} alt="delete" className="iconwidth" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  : "No Main Category added"}
              </tbody>
            </table>
            Categories
            <table className="table check-tbl">
              <thead>
                <tr>
                  <th>Main Category</th>
                  <th>Category</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {cat.length > 0
                  ? cat.map((post, key) => (
                      <tr>
                        <td className="product-item-name font-weight-normal">{post.maincategory}</td>
                        <td className="product-item-name font-weight-normal">{post.category}</td>
                        <td className="product-item-name font-weight-normal">{post.type}</td>

                        <td className="product-item-price font-weight-normal text-no-wrap d-none">{Moment(post.createddate).format("DD-MMM-YYYY")}</td>
                        <td className="product-item-name font-weight-normal">{post.isactive === 1 ? "Active" : <span className="text-red">Inactive</span>}</td>
                        <td>
                          <Link to="#" onClick={(e) => deletecat(post.id)}>
                            <img src={deleteicon} alt="delete" className="iconwidth" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  : "No Category added"}
              </tbody>
            </table>
            Sub Categories
            <table className="table check-tbl">
              <thead>
                <tr>
                  <th>Main Category</th>
                  <th>Category</th>
                  <th>Sub Category</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {subcat.length > 0
                  ? subcat.map((post, key) => (
                      <tr>
                        <td className="product-item-name font-weight-normal">{post.maincategory}</td>
                        <td className="product-item-name font-weight-normal">{post.category}</td>
                        <td className="product-item-name font-weight-normal">{post.subcategory}</td>
                        <td className="product-item-name font-weight-normal">{post.type}</td>
                        <td className="product-item-name font-weight-normal">{post.isactive === 1 ? "Active" : <span className="text-red">Inactive</span>}</td>
                        <td className="product-item-price font-weight-normal text-no-wrap d-none">{Moment(post.createddate).format("DD-MMM-YYYY")}</td>

                        <td>
                          <Link to="#" onClick={(e) => deletesubcat(post.id)}>
                            <img src={deleteicon} alt="delete" className="iconwidth" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  : "No Sub Category added"}
              </tbody>
            </table>
            Brands
            <table className={props.maincategory ? "table check-tbl" : "d-none"}>
              <thead>
                <tr>
                  <th>Brand</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {brand.length > 0
                  ? brand.map((post, key) => (
                      <tr>
                        <td className="product-item-name font-weight-normal">{post.brand}</td>
                        <td className="product-item-name font-weight-normal">{post.type}</td>
                        <td className="product-item-name font-weight-normal">{post.isactive === 1 ? "Active" : <span className="text-red">Inactive</span>}</td>
                        <td className="product-item-price font-weight-normal text-no-wrap d-none">{Moment(post.createddate).format("DD-MMM-YYYY")}</td>

                        <td>
                          <Link to="#" onClick={(e) => deleteBrand(post.id)}>
                            <img src={deleteicon} alt="delete" className="iconwidth" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  : "No Brand added"}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
};

export default AdminPostCategory;
