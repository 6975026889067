import React, { useState, useEffect, useRef } from "react";
import config from "../../config.json";
import { Link, useHistory } from "react-router-dom";
import uuid from "react-uuid";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import firebase from "./../../firebase";
import daintyfirebase from "./../../daintyfirebase";
import JoditEditor from "jodit-react";
import Moment from "moment";
import on from "./../../assets/images/icons/on.png";
import off from "./../../assets/images/icons/off.png";
import edit from "./../../assets/images/icons/edit.png";
import deleteicon from "./../../assets/images/icons/delete.png";
import copy from "./../../assets/images/icons/copy.png";
import show from "./../../assets/images/icons/show.png";
import hide from "./../../assets/images/icons/hide.png";
const Gallery = (props) => {
  const [gallery, setGallery] = useState([]);
  const [networkError, setNetworkError] = useState("");
  const [gallery_image, setGallery_image] = useState("");
  const history = useHistory();
  const [smShow, setSmShow] = useState(false);
  const [showp, setShowp] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [access, setAccess] = useState(false);

  const handleVisible = () => {
    setSmShow(true);
    setTimeout(() => {
      setSmShow(false);
    }, 1000);
  };
  const getGalleryDetails = async () => {
    await fetch(props.serviceurl + "getadmingallery", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          let active = data.data
            //.filter((filter) => filter.isactive === "1")
            .map((data) => {
              return data;
            });
          setGallery(active);
        } else if (data?.status === 499) {
          history.push("/login");
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        // console.log(networkError);
      });
  };
  const deleteGallery = (id, url) => {
    console.log(id);

    fetch(props.serviceurl + "deletegallery", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ Id: id }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(JSON.stringify({ Id: id }));
        deleteFromFirebase(url);

        getGalleryDetails();
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        //console.log(networkError);
      });
  };
  const updategallery = (type, pid, id) => {
    console.log(id);
    let post = {};
    post.gallery_id = pid;
    post.updateddate = new Date();
    post.viewingallery = id;
    fetch(props.serviceurl + "updategallery", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },

      body: JSON.stringify({ data: post }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(JSON.stringify({ data: post }));
        setSuccessMsg(data.message);
        handleVisible();
        getGalleryDetails();
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };

  const deleteFromFirebase = (url) => {
    console.log("File url : " + url);
    var fileRef;
    if (props.page === "DaintyGallery") {
      fileRef = daintyfirebase.storage().refFromURL(url);
    } else {
      fileRef = firebase.storage().refFromURL(url);
    }
    // Delete the file using the delete() method
    fileRef
      .delete()
      .then(function () {
        // File deleted successfully
        console.log("File Deleted");
        setSuccessMsg("Image Deleted Sucessfully");
        handleVisible();
      })
      .catch(function (error) {
        console.log("File error", error);
      });

    console.log("File in database after delete exists : " + fileRef.exists());
  };

  const onChange_image_gallery = async (e, imagename) => {
    setSuccessMsg("Please wait");
    console.log("image name", imagename);
    setSmShow(true);
    const file = e.target.files[0];
    console.log("file", e.target.files[0]);
    if (file.size < 300000) {
      console.log("add products", file);
      const path = localStorage.getItem("name") + "-" + localStorage.getItem("uuid") + "/gallery";

      var storageRef;
      if (props.client === "daintyflavors.com") {
        storageRef = daintyfirebase.app("daintyfb").storage().ref(path);
      } else {
        storageRef = firebase.storage().ref(path);
      }

      const fileRef = storageRef.child(uuid());
      await fileRef.put(file);
      setGallery_image(await fileRef.getDownloadURL());
      console.log("add products", gallery_image);
      setSmShow(false);
      setSuccessMsg("");
    } else {
      setSuccessMsg("Please upload file less than 500 kb");
    }
  };
  const onChange_banner_image = async (e, imagename) => {
    setSuccessMsg("Please wait");
    console.log("image name", imagename);
    setSmShow(true);
    const file = e.target.files[0];
    console.log("file", e.target.files[0]);
    if (file.size < 300000) {
      console.log("add products", file);
      const path = "DAINTYFLAVOURS/slider";
      var storageRef;
      if (props.client === "daintyflavors.com") {
        storageRef = daintyfirebase.app("daintyfb").storage().ref(path);
      } else {
        storageRef = firebase.storage().ref(path);
      }
      const fileRef = storageRef.child("homebanner.jpg");
      await fileRef.put(file);
      setSuccessMsg("Upload Success");
    } else {
      setSuccessMsg("Please upload file less than 300 kb");
    }
  };
  const checkaccess = async () => {
    let datas = {};
    datas.userid = localStorage.getItem("uuid");
    datas.page = props.page;
    await fetch(config.service_url + "checkaccess", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ data: datas }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("access", data);
        if (data.status === 200) {
          setAccess(true);
        } else {
          history.push("/adminhome");
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
      });
  };
  useEffect(() => {
    checkaccess();
    if (access) {
      getGalleryDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: {}, mode: "blur" });
  const onSubmit = (data, e) => {
    let methodname = "addgallery";

    setSuccessMsg("Please wait");
    setSmShow(true);
    e.preventDefault();
    if (localStorage.getItem("uuid") === undefined || localStorage.getItem("uuid") === null) {
      history.push("/");
    } else {
      data.gallery_id = uuid();
      data.createddate = new Date();
      data.createduserid = localStorage.getItem("uuid");
      data.createdby = localStorage.getItem("name");
      data.viewingallery = 0;
      data.isactive = 1;
      data.displaydate = Moment().format("LL");
      data.imageurl = gallery_image;
      data.width = 1;
      data.height = 1;
    }
    console.log("add gallery", data);
    fetch(props.serviceurl + methodname, { method: "POST", headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") }, body: JSON.stringify({ data }) })
      .then((response) => response.json())
      .then((data) => {
        console.log("regitered user", data);
        if (data.status === 200) {
          e.target.reset();
          setSuccessMsg(data.message);
          setSmShow(false);
          handleVisible();
          setGallery_image("");
          getGalleryDetails();
        } else {
          setSuccessMsg(data.message);
        }
      })
      .catch((err) => {
        setSuccessMsg("Something went wrong, Please try again later!!");
      });
  };

  {
    return access ? (
      <div>
        <Modal size="sm" show={smShow} onHide={() => setSmShow(false)}>
          <Modal.Header closeButton>{successMsg}</Modal.Header>
        </Modal>
        <div id="review_form_wrapper">
          <div className={!showp ? "" : "d-none"} id="review_form">
            <button className="btnadmin my-2" onClick={(e) => setShowp((showp) => !showp)}>
              View all {props.page} ({gallery.length > 0 ? gallery.length : 0})
            </button>
            <div id="respond" className="comment-respond">
              <h3 className="comment-reply-title" id="reply-title">
                Add Image and Save
              </h3>

              <form className="comment-form" onSubmit={handleSubmit(onSubmit)} id="frmPostadd">
                <div className="comment-form-author">
                  <label>
                    Image Title <span className="required">*</span>
                  </label>
                  <input type="text" required aria-required="true" size="30" name="title" ref={register} id="title" />
                  {/* {errors.p_name && "Product name is required"} */}
                </div>
                <div className="comment-form-author">
                  <label>Image Description</label>
                  <input type="text" aria-required="true" size="30" name="description" ref={register} id="description" />
                  {/* {errors.p_name && "Product name is required"} */}
                </div>

                <div className="comment-form-author">
                  <div className="row">
                    <div className="col">
                      <label>Post Image (max. size 300 kb)</label>
                      <input placeholder="Upload Product Image" name="postimage" onChange={onChange_image_gallery} accept="image/*" className="" type="file" />
                    </div>
                    <div className="col">
                      <label>Post Image</label>
                      <img className="smallimage" src={gallery_image} />
                    </div>
                  </div>
                </div>

                <div className="form-submit">
                  <button type="submit" className="btnadmin btnhover">
                    Save Gallery Image
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div id="product" className={showp ? "table check-tbl" : "d-none"}>
            <button
              className="btnadmin my-2"
              onClick={(e) => {
                setShowp((showp) => !showp);
                setGallery_image("");
              }}
            >
              Add Image
            </button>
            <table className="table check-tbl">
              <thead>
                <tr>
                  <th>Gallery</th>
                  <th>Image</th>
                  <th>Title</th>
                  <th>ImageURL</th>
                  <th>Create Date</th>

                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {gallery.length > 0
                  ? gallery.map((post, key) => (
                      <tr>
                        <td>
                          <Link to="#" onClick={(e) => updategallery("viewingallery", post.gallery_id, post.viewingallery === 1 ? 0 : 1)}>
                            {post.viewingallery === 0 ? <img src={hide} alt="hide" className="iconwidth" /> : <img src={show} alt="show" className="iconwidth" />}
                          </Link>{" "}
                        </td>
                        <td className="product-item-img">
                          <img className="smallimage" src={post.imageurl} height="15" alt="" />
                        </td>
                        <td className="product-item-name font-weight-normal">{post.title}</td>
                        <td className="product-item-name font-weight-light text-small">
                          <Link
                            to="#"
                            onClick={() => {
                              navigator.clipboard.writeText(post.imageurl);
                            }}
                            className="py-1"
                          >
                            <img src={copy} alt="copy" className="iconwidth" />
                          </Link>
                        </td>
                        <td className="product-item-price font-weight-normal text-no-wrap">{Moment(post.createddate).format("DD-MMM-YYYY")}</td>

                        <td>
                          <Link to="#" onClick={(e) => deleteGallery(post.gallery_id, post.imageurl)}>
                            <img src={deleteicon} alt="delete" className="iconwidth" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  : "No Post added"}
              </tbody>
            </table>
          </div>
        </div>
        {/* get this daintyflavors.com from log in response */}
        <div id="bannerupdate" className={props.client === "daintyflavors.com" ? "" : "d-none"}>
          <form className="comment-form" id="frmPostadd">
            <div className="comment-form-author1">
              <div className="row">
                <div className="col">
                  <label>Update Banner Image (max. size 300 kb)</label>
                  <i className="small">Choose the file, it will be updated in the banner.</i>
                  <input placeholder="Upload Banner Image" name="bannerimage" onChange={onChange_banner_image} accept="image/*" className="" type="file" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    ) : (
      <div></div>
    );
  }
};

export default Gallery;
